import React, { Component } from 'react'
import axios from 'axios'
import classNames from 'classnames'
import { Helmet } from 'react-helmet-async'

import Link, { path } from '../Routes'
import I18n from '../I18n'
import Loaf from '../Loaf/Loaf'

import RumcsBlock from '../Rumcs/RumcsBlock'
import Covid from '../Requests/Covid'

import page from '../Page.module.css'
import styles from './RumcsIndex.module.css'

class Index extends Component {
  state = {
    regionCount: 83,
    rumcSchools : 660,
    events: null,
    rumcs: null
  }

  componentDidMount () {
    this._loadAsyncData()
  }

  componentWillUnmount () {
    if (this._asyncRequest) this._asyncRequest.cancel()
  }

  _loadAsyncData = async () => {
    this._asyncRequest = axios.CancelToken.source()

    const res = await axios.get(path('rumcs_path', {}, true), { cancelToken: this._asyncRequest.token })
    this._asyncRequest = null

    this.setState({ ...res.data })
  }

  render () {
    const { rumcs, rumcSchools, regionCount } = this.state

    const ministry = {
      24 : "Минпросвещения России",
      21 : "Минпросвещения России",
      17 : "Минпросвещения России",
      34 : "Минкультуры России",
      35 : "Минспорт России",
      38 : "Минтранс России",
      36 : "Минздрав России",
      37 : "Минсельхоз России",
    }
    return (
      <div className={page.wrapper}>
        <div className={page.container}>
          <Loaf>
            <Link to="root_path">{I18n.t('nav.root')}</Link>
          </Loaf>
          <div className={classNames(page.title, styles.lmt)}>
            <h1>
              {I18n.t('meta.rumcs.title')}
            </h1>

            <Helmet>
              <title>{I18n.t('meta.rumcs.title')}</title>
            </Helmet>
          </div>

          <div className={styles.main}>
            <section className={classNames(styles.section, styles.map)}>
              <RumcsBlock rumcs={rumcs} />
            </section>

            <section className={classNames(styles.about)}>
              <div className={styles.whatIsThis}>
                <h2>
                  Ресурсные учебно-методические центры
                </h2>

                <p>
                  Ресурсные учебно-методические центры обеспечивают комплексный подход для развития системы инклюзивного высшего образования в России, оказывая консультационную поддержку вузам-партнёрам, лицам с инвалидностью и ОВЗ и другим заинтересованным лицам, разрабатывая современные подходы и технологии к профориентации и обучению, а также развитию форм содействия трудоустройству, предоставляя доступ к центрам коллективного пользования специальными техническими средствами обучения.
                </p>
              </div>

              {rumcs &&
              <div className={styles.howMany}>
                <div className={styles.lrg}>
                  {rumcs.length}
                </div>
                {I18n.t('rumcs.count_without_count', { count: rumcs.length })} во всех регионах России
              </div>}

              {rumcSchools && <div className={styles.agreements}>
                <div className={styles.lrg}>
                  {rumcSchools}
                </div>
                вузов-партнёров
              </div>}
            </section>

            {rumcs &&
              <section className={classNames(styles.section, styles.rumcs)}>
                {rumcs.map(rumc =>
                  <Link to="rumc_path" params={{ id: rumc.id }} className={classNames(styles.a_in_block, styles.rumc)} key={rumc.id}>
                    <div>
                      {rumc.title}
                    </div>
                    {ministry[rumc.id] &&
                      <div className={styles.ministry}>
                        {ministry[rumc.id]}
                      </div>
                    }
                  </Link>
                )}
              </section>
            }

            <div className={styles.orgPartnersWrapper}>
              <Link to="partners_index_path" className={styles.orgPartners}>
                <div>Список вузов-партнеров РУМЦ</div>
              </Link>
            </div>

            <section className={classNames(styles.section, styles.request)}>
              <Covid />
              {/* <RequestNewBlock text={'С 20 июня работает «горячая линия» сети ресурсных учебно-методических центров по вопросам организации обучения лиц с инвалидностью'} title={'Горячая линия по вопросам инклюзивного образования'} /> */}
            </section>

            <br />
            <div>
              <h3>Информационные партнеры, на сайтах которых размещены материалы о проекте и деятельности вузов РУМЦ:</h3>
              <ul>
                <li>
                  <a href='https://linkemed.ru/inva_info?page=rumc' target='_blank'>Linkemed.ru</a> (поисковый сервис о здоровье и мире медицины)
                </li>
                <li>
                  <a href='https://rirportal.ru/ru-RU/news/novye-tehnologii-pomogut-nezracim-ludam-orientirovatsa-v-prostranstve' target='_blank'>Реабилитационная индустрия России</a> (информационно-справочный портал)
                </li>
              </ul>

              <h3>
                Материалы встречи с <a href='https://social-tech.ru/' target='_blank'>АНО «Технологии возможностей»</a> (2022 г.): 
              </h3>
              <ul>
                <li><a href='https://disk.yandex.ru/i/lpD3pXSYVk7W0w' target='_blank'>видеозапись рабочей встречи</a></li>
              </ul>


              <h3>Информационно-справочные материалы по деятельности сети РУМЦ и ПО</h3>
              <ul>
                <li>
                  <a href='/uploads/document/projects/information_reference_materials/1-meropriyatiya-v-ramkax-desyatiletiya-detstva.pdf' target='_blank'>Мероприятия в рамках Десятилетия детства</a>
                </li>
                <li>
                  <a href='/uploads/document/projects/information_reference_materials/2-spravka-po-voprosam-proforientacii.pdf' target='_blank'>Справка по вопросам профориентации, сопровождения и трудоустройства лиц с инвалидностью</a>
                </li>
                <li>
                  <a href='/uploads/document/projects/information_reference_materials/3-aktualizaciya-koncepcii-razvitiya-p-sluzhby.pdf' target='_blank'>Актуализация Концепции развития психологической службы</a>
                </li>
                <li>
                  <a href='/uploads/document/projects/information_reference_materials/4-o-sisteme-celevogo-obucheniya.pdf' target='_blank'>О системе целевого обучения</a>
                </li>
              </ul>


              <h3>Ссылки на полезные цифровые сервисы и информационные ресурсы о доступной среде для лиц с инвалидностью и ОВЗ:</h3>

              <ul>
                <li><a href='https://rirportal.ru/ru-RU/potrebitelyam-i-zakupshchikam/model-solution' target='_blank'>Модельные архитектурные решения по созданию доступной среды</a></li>
                <li><a href='https://rirportal.ru/ru-RU/potrebitelyam-i-zakupshchikam/catalog' target='_blank'>Каталог товаров реабилитационной направленности, верифицированных Минпромторгом России</a></li>
                <li><a href='https://mds.rirportal.ru/ru-RU/landing' target='_blank'>Платформа для оценки доступности объектов и проведения паспортизации Моя доступная страна</a></li>
                <li><a href='https://pazl.academy/' target='_blank'>Платформа для проведения онлайн-мероприятий, размещения онлайн-курсов и тестирований – ПАЗЛ</a></li>
                <li><a href='https://нко-онлайн.москва/video' target='_blank'>«Азбука доступности». Видеоуроки по доступной среде</a></li>
                <li><a href='https://нко-онлайн.москва/azbuka' target='_blank'>«Азбука доступности». Виртуальные сцены</a></li>
                {/* <li>Виртуальная выставка «НКО-онлайн», включающая в себя выставочную зону, виртуальный музей добрых дел, центральный выставочный павильон для тематических выставок, дискуссионный центр, Дом НКО, виртуальный  <a href='https://www.nko-online.moscow/exhibition' target='_blank'>Форум</a> (участие в выставке бесплатное; чтобы разместить стенд, достаточно заполнить заявку по ссылке: <a href='https://www.nko-online.moscow/application' target='_blank'>https://www.nko-online.moscow/application</a></li> */}
                <li><a href='https://adaptis.pro' target='_blank'>Сайт диспетчерского центра перевода русского жестового языка (РЖЯ)</a></li>
                <li>Технологии для людей с нарушениями слуха ООО «Адаптис» (диспетчерский центр «Адаптис», мобильное приложение «Адаптис» для iOS и Android, словарь русского жестового языка, создание компьютерного переводчика РЖЯ с использованием 3D-аватара): <a href='https://adaptis.pro/' target='_blank'>https://adaptis.pro/</a></li>
                <li><a href='https://feel.sfedu.ru/' target='_blank'>Система навигации "Шестое чувство" (ФГАОУ ВО "Южный федеральный университет")</a></li>
                {/* <li><a href='http://www.tyumen-technopark.ru/rezidenty/proekty/48/' target='_blank'>Электронная тетрадь СИОЛЛ - СИОЛЛ - электронный учебник для незрячих людей  – Западно-Сибирский Инновационный Центр</a></li> */}
              </ul>

            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Index
